import { useLocalStorage } from 'usehooks-ts';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CustomListGroup, { ListItem } from '../lists/CustomListGroup';
import {
  useGetApiVersionsArticleByIdQuery,
  NamedVersion,
  usePutApiVersionsToggleDisableByIdMutation,
  useGetApiCategoryTreeQuery,
  useGetApiBookmarkFoldersQuery,
} from '../../redux/store/api/api';
import { addMessage, removeMessage } from '../../redux/store/layout/slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setContextVersion,
  setDeleteVersionDialogOpened,
  setRenameVersionDialogOpened,
  setCurrentVersions,
  selectActiveVersion,
  setActiveVersion,
  setComparerIsActive,
  setAddBookmarkDialogOpened,
  setDeleteBookmarkDialogOpened,
  setContextBookmark,
} from '../../redux/store/content/slice';
import { ContextAction } from '../dropdown-menus/types';
import {
  downloadFile,
  getVersionIcon,
  getVersionIconColorClass,
  getVersionTranslationKeyOfIconDescription,
  getVersionName,
  formatToLocalDateString,
} from '../../shared/utils';
import {
  articleIdUrlParam,
  EDIT_MODE_KEY,
  versionIdUrlParam,
} from '../../shared/constants';
import { useArticlesExportByIdMutation } from '../../redux/store/api/fileApi';
import { RightKey } from '../../shared/enums';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { getArticleUrl, getEditArticleUrl } from '../../shared/urlBuilder';

function VersionList(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dangerClass = 'text-danger';
  const [showMoreVersionsIsActive, setShowMoreVersionsIsActive] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const articleId: string = searchParams.get(articleIdUrlParam) || '';
  const versionId: string = searchParams.get(versionIdUrlParam) || '';
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const activeVersion = useAppSelector(selectActiveVersion);
  const [exportArticle] = useArticlesExportByIdMutation();
  const [
    toggleDisable,
    { isError: toggleVersionIsError, error: toggleVersionError },
  ] = usePutApiVersionsToggleDisableByIdMutation();
  const { data: bookmarkTreeData } = useGetApiBookmarkFoldersQuery();

  const category = useGetCategoryByArticleId(articleId);
  // permission
  const userCanEditArticle =
    category?.permittedActions?.includes(
      RightKey.RightArticleManagementEditArticle,
    ) || false;
  const userCanDeleteVersion =
    category?.permittedActions?.includes(
      RightKey.RightArticleManagementDeleteVersion,
    ) || false;

  const {
    data: versionData,
    isFetching,
    isError: getVersionsIsError,
    error: getVersionError,
    refetch,
  } = useGetApiVersionsArticleByIdQuery(
    articleId !== ''
      ? { id: articleId, editMode: editModeIsActive }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { refetch: refetchCategoryTree } = useGetApiCategoryTreeQuery();
  const maxDisplayedVersions = 5;

  const filteredVersions = useMemo<NamedVersion[]>(
    () => versionData?.resultObject?.filter((v) => !v.draft) || [],
    [versionData],
  );

  const displayedVersions = useMemo<NamedVersion[]>(() => {
    if (!versionData || !activeVersion.id) {
      return [];
    }

    if (filteredVersions.indexOf(activeVersion) >= maxDisplayedVersions) {
      setShowMoreVersionsIsActive(true);
    }

    if (
      filteredVersions.indexOf(activeVersion) < maxDisplayedVersions &&
      !showMoreVersionsIsActive
    ) {
      return filteredVersions.slice(0, maxDisplayedVersions);
    }

    return filteredVersions;
  }, [versionData, showMoreVersionsIsActive, activeVersion]);

  useEffect(() => {
    if (getVersionsIsError) {
      dispatch(
        addMessage({
          id: 'GetVersionError',
          messageKeyBody:
            getVersionError && 'data' in getVersionError
              ? getVersionError.data?.messageKey
              : 'unknownError',
          variant: 'danger',
        }),
      );
    }

    if (toggleVersionIsError) {
      dispatch(
        addMessage({
          id: 'ToggleVersionError',
          messageKeyBody:
            toggleVersionError && 'data' in toggleVersionError
              ? toggleVersionError.data?.messageKey
              : 'unknownError',
          variant: 'danger',
        }),
      );
    }
  }, [getVersionsIsError, toggleVersionIsError]);

  useEffect(() => {
    if (
      !isFetching &&
      versionData?.resultObject &&
      versionData.resultObject.length > 0
    ) {
      dispatch(setCurrentVersions(versionData?.resultObject));

      if (
        versionId !== '' &&
        versionData.resultObject.find((v) => v.id === versionId)
      ) {
        dispatch(
          setActiveVersion(
            versionData.resultObject?.find(
              (version) => version.id === versionId,
            ) || {},
          ),
        );
      } else {
        dispatch(
          setActiveVersion(
            versionData.resultObject.find((v) => v.isRecentVersion) || {},
          ),
        );
      }
    }

    if (
      !isFetching &&
      versionData?.resultObject &&
      versionData.resultObject.length === 0
    ) {
      navigate('/', {
        replace: true,
      });
    }
  }, [versionData, versionId]);

  const getContextActionsForEditMode = (
    version: NamedVersion,
  ): ContextAction[] => {
    const actions: ContextAction[] = [];

    if (userCanEditArticle) {
      actions.push({
        helpId: 'help_3_3_4',
        name: translation('editVersion'),
        onClick: () => {
          dispatch(setActiveVersion(version));
          navigate(
            getEditArticleUrl(version.articleId || '', version.id || ''),
            {
              replace: true,
            },
          );
        },
        iconClass: 'icon-edit',
        iconColorClass: 'text-body',
      });

      actions.push({
        helpId: 'help_3_3_6',
        name: translation('renameVersion'),
        onClick: () => {
          dispatch(setContextVersion(version));
          dispatch(setRenameVersionDialogOpened(true));
        },
        iconClass: 'icon-umbenennen',
        iconColorClass: 'text-body',
      });

      actions.push({
        helpId: 'help_3_3_5',
        name: translation(
          version.disabled ? 'activateVersion' : 'deactivateVersion',
        ),
        onClick: () => {
          toggleDisable({ id: version.id || '' })
            .unwrap()
            .then(() => {
              refetchCategoryTree();
              refetch();
            });
        },
        iconClass: version.disabled ? 'icon-aktivieren' : 'icon-deaktivieren',
        iconColorClass: version.disabled ? 'text-success' : dangerClass,
      });
    }

    if (
      versionData?.resultObject &&
      filteredVersions.length > 1 &&
      userCanDeleteVersion
    ) {
      actions.push({
        name: translation('deleteVersion'),
        onClick: () => {
          dispatch(setContextVersion(version));
          dispatch(setDeleteVersionDialogOpened(true));
        },
        iconClass: 'icon-trash',
        iconColorClass: dangerClass,
        addDividerAfterItem: true,
      });
    }

    return actions;
  };

  const getContextActions = (version: NamedVersion) => {
    const actions: ContextAction[] = [];

    if (editModeIsActive) {
      actions.push(...getContextActionsForEditMode(version));
    }

    if (activeVersion.id !== version.id && !version.pdfFileId) {
      actions.push({
        helpId: 'help_3_3_9',
        iconClass: 'icon-vergleichen',
        name: translation('compareWithActiveVersion'),
        onClick: () => {
          dispatch(setContextVersion(version));
          dispatch(setComparerIsActive(true));
        },
        addDividerAfterItem: true,
      });
    }

    const relatedBookmark = bookmarkTreeData?.resultObject?.bookmarks?.find(
      (b) => b.versionId === version.id && !b.markId,
    );

    if (relatedBookmark) {
      actions.push({
        name: translation('deleteBookmark'),
        iconClass: 'icon-merkliste_remove',
        iconColorClass: 'text-danger',
        onClick: () => {
          dispatch(setContextBookmark(relatedBookmark));
          dispatch(setDeleteBookmarkDialogOpened(true));
        },
        addDividerAfterItem: true,
      });
    } else {
      actions.push({
        helpId: 'help_2_3_1',
        iconClass: 'icon-merkliste_new',
        iconColorClass: 'text-success',
        name: translation('addBookmark'),
        onClick: () => {
          dispatch(setContextVersion(version));
          dispatch(setAddBookmarkDialogOpened(true));
        },
        addDividerAfterItem: true,
      });
    }

    if (version.isRecentVersion) {
      actions.push({
        helpId: 'help_3_3_7',
        iconClass: 'icon-einzel_pdf',
        iconColorClass: dangerClass,
        name: translation('exportVersionAsPdf'),
        onClick: () => {
          dispatch(
            addMessage({
              id: 'GenerateVersionPdfInVersionList',
              messageKeyHeader: translation('pdfIsGenerating'),
              messageKeyBody: translation('pleaseBePatient'),
              variant: 'info',
              timeInMs: 9000000000,
            }),
          );
          exportArticle({
            id: version.articleId || '',
            includeVersions: false,
          })
            .unwrap()
            .then((url) => {
              downloadFile(url, `${version.title?.replace(/\s/g, '')}.pdf`);
              dispatch(removeMessage('GenerateVersionPdfInVersionList'));
              dispatch(
                addMessage({
                  id: 'GenerateVersionPdfInVersionListSuccess',
                  messageKeyBody: 'generatePdfSuccess',
                  variant: 'success',
                }),
              );
            })
            .catch(() => {
              dispatch(removeMessage('GenerateVersionPdfInVersionList'));
              dispatch(
                addMessage({
                  id: 'ExportVersionPdfInVersionListError',
                  messageKeyBody: 'unknownError',
                  variant: 'danger',
                }),
              );
            });
        },
      });
    }

    actions.push({
      iconClass: 'icon-versions_pdf',
      iconColorClass: dangerClass,
      name: translation('exportArticleVersionsAsPdf'),
      onClick: () => {
        dispatch(
          addMessage({
            id: 'GenerateVersionsPdfInVersionList',
            messageKeyHeader: 'pdfIsGenerating',
            messageKeyBody: 'pleaseBePatient',
            variant: 'info',
            timeInMs: 9000000000,
          }),
        );
        exportArticle({
          id: version.articleId || '',
          includeVersions: true,
        })
          .unwrap()
          .then((url) => {
            downloadFile(
              url,
              `${version.title?.replace(/\s/g, '')}_${translation(
                'allVersionsPdfExtension',
              )}.pdf`,
            );
            dispatch(removeMessage('GenerateVersionsPdfInVersionList'));
            dispatch(
              addMessage({
                id: 'GenerateVersionPdfInVersionListSuccess',
                messageKeyBody: 'generatePdfSuccess',
                variant: 'success',
              }),
            );
          })
          .catch(() => {
            dispatch(removeMessage('GenerateVersionsPdfInVersionList'));
            dispatch(
              addMessage({
                id: 'ExportVersionsInVersionListError',
                messageKeyBody: 'unknownError',
                variant: 'danger',
              }),
            );
          });
      },
    });

    return actions;
  };

  return (
    <>
      {category?.takeEffectIn && (
        <p>
          {`${translation('revisionFrom')} ${formatToLocalDateString(
            category?.takeEffectIn,
          )}`}
        </p>
      )}
      {(isFetching || !versionData) && <p>{translation('loaded')}</p>}
      {!isFetching && versionData?.resultObject && (
        <>
          <CustomListGroup
            activeListItem={activeVersion.id || ''}
            listItems={displayedVersions.map((version) => {
              const contextBookmark =
                bookmarkTreeData?.resultObject?.bookmarks?.find(
                  (b) => b.versionId === version.id && !b.markId,
                );
              const versionName = getVersionName(
                version.name || '',
                translation('versionFrom'),
                version.validFrom || '',
              );

              return {
                id: version.id,
                content: <span title={versionName}>{versionName}</span>,
                onClick: !version.disabled
                  ? () => {
                      dispatch(setActiveVersion(version));
                      if (location.pathname.includes('edit-draft')) {
                        navigate(
                          getArticleUrl(
                            version.articleId || '',
                            version.id || undefined,
                          ),
                          { replace: true },
                        );
                      } else {
                        setSearchParams(
                          {
                            articleId: version.articleId || '',
                            versionId: version.id || '',
                          },
                          { replace: true },
                        );
                      }
                    }
                  : undefined,
                contextActions: getContextActions(version),
                iconClass: contextBookmark
                  ? 'icon-merkliste_version'
                  : getVersionIcon(version),
                iconColorClass: getVersionIconColorClass(version),
                iconDescription: translation(
                  getVersionTranslationKeyOfIconDescription(version),
                ),
              } as ListItem;
            })}
          />
          {versionData.resultObject.length > maxDisplayedVersions &&
            filteredVersions.indexOf(activeVersion) < maxDisplayedVersions && (
              <Button
                className='m-auto mt-2'
                variant='outline-primary'
                onClick={() => {
                  setShowMoreVersionsIsActive(!showMoreVersionsIsActive);
                }}>
                {translation(
                  showMoreVersionsIsActive
                    ? 'showLessVersions'
                    : 'showMoreVersions',
                )}
              </Button>
            )}
        </>
      )}
    </>
  );
}

export default VersionList;
