import DescriptiveIcon from '../descriptive-icons/DescriptiveIcon';

interface ICustomTabTitleProps {
  iconClassName: string;
  titleClassName: string;
  title: string;
  showTabTitle?: boolean;
}

function CustomTabTitle({
  iconClassName,
  titleClassName,
  title,
  showTabTitle,
}: ICustomTabTitleProps) {
  return (
    <>
      {!showTabTitle && (
        <DescriptiveIcon iconClass={iconClassName} description={title} />
      )}
      {showTabTitle && (
        <>
          <i className={`${iconClassName} me-2`} aria-hidden />
          <span className={titleClassName} aria-label={title}>
            {title}
          </span>
        </>
      )}
    </>
  );
}

CustomTabTitle.defaultProps = { showTabTitle: false };

export default CustomTabTitle;
