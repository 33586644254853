import { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import {
  RecentChangesItem,
  useGetApiArticlesRecentChangesQuery,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import Loader from '../loader/Loader';
import LastChangesItems from './LastChangesItems';
import CustomTabTitle from '../tabs/CustomTabTitle';

interface ILastChangesProps {
  showTabTitle?: boolean;
}

function LastChanges({ showTabTitle }: ILastChangesProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const tabTitleClassName = 'last-changes-tab-title-text';

  const {
    data: recentChanges,
    isFetching,
    isError,
    error,
  } = useGetApiArticlesRecentChangesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const centralContentChanges = recentChanges?.resultObject
    ?.centralContentsChangesItems as RecentChangesItem[];
  const internalAreaChanges = recentChanges?.resultObject
    ?.internalAreaChangesItems as RecentChangesItem[];
  const markNoteChanges = recentChanges?.resultObject
    ?.noteMarkChangesItems as RecentChangesItem[];

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetLastChangesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <>
      {isFetching && <Loader />}
      {!isFetching && recentChanges?.resultObject && (
        <Tabs
          className={`border-top-0 mt-1${
            showTabTitle ? '' : ' justify-content-center'
          }`}>
          <Tab
            eventKey='centralContent'
            className='mt-3'
            title={
              <CustomTabTitle
                showTabTitle={showTabTitle}
                iconClassName='icon-versionen last-changes-central-content-icon'
                titleClassName={tabTitleClassName}
                title={translation('centralContent')}
              />
            }>
            <LastChangesItems recentChanges={centralContentChanges} />
          </Tab>
          <Tab
            eventKey='internalArea'
            className='mt-3'
            title={
              <CustomTabTitle
                showTabTitle={showTabTitle}
                iconClassName='icon-interne_da last-changes-internal-area-icon'
                titleClassName={tabTitleClassName}
                title={translation('internalArea')}
              />
            }>
            <LastChangesItems recentChanges={internalAreaChanges} />
          </Tab>
          <Tab
            eventKey='marksAndNotes'
            className='mt-3'
            title={
              <CustomTabTitle
                showTabTitle={showTabTitle}
                iconClassName='icon-benutzernotizen last-changes-marks-notes-icon'
                titleClassName={tabTitleClassName}
                title={translation('marksAndNotes')}
              />
            }>
            <LastChangesItems recentChanges={markNoteChanges} />
          </Tab>
        </Tabs>
      )}
    </>
  );
}

LastChanges.defaultProps = { showTabTitle: null };

export default LastChanges;
