import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { RecentChangesItem } from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import './LastChanges.scss';
import { getArticleUrl } from '../../shared/urlBuilder';
import { formatToLocalDateString, getVersionName } from '../../shared/utils';
import { UpdateReason } from '../../shared/enums';
import DescriptiveIcon from '../descriptive-icons/DescriptiveIcon';

interface ILastChangesProps {
  recentChanges: RecentChangesItem[];
}

function LastChangesItems({ recentChanges }: ILastChangesProps): JSX.Element {
  const { t: translation } = useTranslation();

  const getLastChangesItem = (item: RecentChangesItem): JSX.Element => (
    <div className='last-changes-item-header'>
      <p className='d-flex'>
        <span
          style={{
            flex:
              (item.updateReasons?.length ?? 0) > 0 ? '0 0 105px' : undefined,
          }}
          className='last-changes-icon-container'>
          <i
            className={`me-2 align-bottom ${item.iconCssClass || ''}`}
            style={item.iconColor ? { color: item.iconColor } : undefined}
            aria-hidden
          />
          {item.updateReasons?.includes(UpdateReason.Content) && (
            <DescriptiveIcon
              iconClass='me-2 align-bottom icon-artikel'
              description={translation('contentChanged')}
            />
          )}
          {item.updateReasons?.includes(UpdateReason.References) && (
            <DescriptiveIcon
              iconClass='me-2 align-bottom icon-verlinkungen'
              description={translation('referencesChanged')}
            />
          )}
          {item.updateReasons?.includes(UpdateReason.Attachments) && (
            <DescriptiveIcon
              iconClass='me-2 align-bottom icon-attachments'
              description={translation('attachmentsChanged')}
            />
          )}
        </span>
        <span className='text-break'>
          {item.categoryAbbreviation ? `${item.categoryAbbreviation}, ` : ''}
          {`${parse(
            item.treeTitle || item.versionTitle || '',
          )} (${getVersionName(
            item.versionName || '',
            translation('versionFrom'),
            item.validFrom || '',
          )})`}
        </span>
      </p>
      <span className='last-changes-date text-muted'>
        {item.lastModified ? formatToLocalDateString(item.lastModified) : ''}
      </span>
    </div>
  );

  return recentChanges.length === 0 ? (
    <p>{translation('noRecentChangesInThisCategory')}</p>
  ) : (
    <CustomListGroup
      listItems={recentChanges.map((r, index) => ({
        id: index.toString(),
        href: getArticleUrl(r.articleId || '', r.versionId || undefined),
        content: getLastChangesItem(r),
      }))}
    />
  );
}

export default LastChangesItems;
